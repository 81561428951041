import React from 'react';
import PropTypes from 'prop-types';
import { InfoOutlined } from '@mui/icons-material';

const NotificationBanner = (props) => {
    const { title, message, close } = props; 
    if (message) {
        setTimeout(close, 5000)
    }
    return (
        <div className={`alert__banner ${props.type}`}>
              <div className="notification-column">  
                <InfoOutlined />
                <div>
                    <h3>{props.title}</h3>
                    <p>{props.message}</p> 
                </div>
                </div>
                <div className="closeIcon" onClick={close}>X</div>                  
        </div>
    )
};

NotificationBanner.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
    type: PropTypes.string,
    close: PropTypes.func
};

export default NotificationBanner;