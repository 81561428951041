import React from 'react';
import { connect } from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

let AddSingleEmployeeForm = (props) => {

    const {handleSubmit, pristine, submitting} = props;

    return (
        <form className="l-form" onSubmit={handleSubmit}>
          <h3>Adding one(1) Employee </h3>
          <div className="dependents-info-wrapper-mobile">
            <div>
              {/* <InfoOutlinedIcon /> */}
            </div>
            <div>
                <p>Dependents are your employees' family members covered under your plan. For example, 1 spouse + 2 kids = 3 dependents. If no family members are covered, leave it blank.</p>
            </div>
        </div>
          <div className='add-single-form-wrapper'>
            
          <div className="input-field-container">
          <label>First name</label>
          <Field component="input" className="form-input" type="text" name="first_name" placeholder="Enter First Name"/>
          </div>
          <div className="input-field-container">
          <label>Last Name</label>
          <Field component="input" className="form-input" type="text" name="last_name" placeholder="Enter Last Name "/>
          </div>
          <div className="input-field-container">
          <label>Email Address</label>
          <Field component="input" className="form-input" type="email" name="email_address" placeholder="Enter Email Address"/>
          </div>
          <div className="input-field-container">
          <label>Phone Number</label>
          <Field component="input" className="form-input" type="number" name="phone_number" placeholder="Enter Phone number"/>
          </div>
         
          <Field className="hidden" name="access_token" component="input" style={{display:"none"}}/>
        
          <Field className="hidden" name="client_id" component="input" style={{display:"none"}}/>
          <div className="input-field-container">
          <label>Select a Plan</label>
          <Field component="select" className="form-input" name="product_id">
            <option>Select Plan</option>
            {props.invoicePlans.map((plan)=>(
              <option key={plan.id} value={plan.id}>{plan.name}</option>
            ))}
          </Field>
          </div>
          <div className="input-field-container">
          <label>Number of Beneficiaries</label>
          <Field component="input" className="form-input" type="number" name="no_of_dependant" placeholder="Number of dependants"/>
          </div>
          <button className="form-button upload" disabled={pristine || submitting}>Submit</button>
          </div>
        <div className="dependents-info-wrapper">
            <div>
              {/* <InfoOutlinedIcon /> */}
            </div>
            <div>
                <p>Dependents are your employees' family members covered under your plan. For example, 1 spouse + 2 kids = 3 dependents. If no family members are covered, leave it blank.</p>
            </div>
        </div>
        </form>
    );
};

AddSingleEmployeeForm = reduxForm({
    form:"addSingleEmployeeForm"
})(AddSingleEmployeeForm);

AddSingleEmployeeForm = connect(({session})=>({
    initialValues:{access_token:session.user.credentials.accessToken, client_id: session.user.companyInfo.id},
    enableReinitialize:true
}))(AddSingleEmployeeForm);

export default AddSingleEmployeeForm;